// src/pages/NotFound/NotFound.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Main.css';  // Optional, for styling the page

const NotFound = () => {
    return (
        <div className="notfound-container">
            <h1>404 - Page Not Found</h1>
            <p>Oops! The page you're looking for doesn't exist.</p>
            <Link to="/">
                <button className="back-home-button">Back to Home</button>
            </Link>
        </div>
    );
};

export default NotFound;
