import React, { useState } from 'react';
import '../AdminPortal/AdminPortal.css';

const API_URL = process.env.REACT_APP_API_URL;

const AdminPortal = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        assistantId: '',
        domainName: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [userCreated, setUserCreated] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateDomain = async () => {
        try {
            const response = await fetch(`${API_URL}/api/check-domain?domain=${formData.domainName}`);
            const data = await response.json();
            return data.exists; // true if domain exists, false otherwise
        } catch (error) {
            console.error("Domain validation error:", error);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Validate required fields
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.assistantId || !formData.domainName) {
            setError("All fields are required.");
            return;
        }

        // Validate domain
        const domainExists = await validateDomain();
        if (!domainExists) {
            setError("Domain name is invalid or does not exist in the Train table.");
            return;
        }

        // Insert data into the database
        try {
            const response = await fetch(`${API_URL}/api/create-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            
            if (response.ok) {
                setSuccess("User created successfully.");
                setUserCreated(true);
            } else {
                setError("An error occurred while creating the user.");
            }
        } catch (error) {
            console.error("Submission error:", error);
            setError("An error occurred while creating the user.");
        }
    };

    const handleSendEmail = async () => {
        setError('');
        setSuccess('');

        try {
            const response = await fetch(`${API_URL}/send-bot-ready-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    firstname: formData.firstName,
                    assistantid: formData.assistantId,
                    botid: formData.botid, // Replace with actual bot ID if available
                }),
            });

            if (response.ok) {
                setSuccess("Email sent successfully!");
            } else {
                setError("An error occurred while sending the email.");
            }
        } catch (error) {
            console.error("Email sending error:", error);
            setError("An error occurred while sending the email.");
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Create New User</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}
            <div>
                <label>First Name</label>
                <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </div>
            <div>
                <label>Last Name</label>
                <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </div>
            <div>
                <label>Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
                <label>Assistant ID</label>
                <input type="text" name="assistantId" value={formData.assistantId} onChange={handleChange} required />
            </div>
            <div>
                <label>Domain Name</label>
                <input type="text" name="domainName" value={formData.domainName} onChange={handleChange} required />
            </div>
            <div>
                <label>Bot ID - Use this field for Send Email</label>
                <input type="text" name="botid" value={formData.botid} onChange={handleChange} />
            </div>
            <button type="submit">Create New User</button>
            <button
                type="button"
                onClick={handleSendEmail}
                style={{ marginTop: '10px', backgroundColor: 'green', color: 'white' }}
            >
                Send Email
            </button>
        </form>
    );
};

export default AdminPortal;
