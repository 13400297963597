import React, { useState } from 'react';
import '../SignUp/SignUp.css'; // Use this CSS for styling
import { useNavigate } from 'react-router-dom';
export const API_URL = process.env.REACT_APP_API_URL;

const Register = () => {
    const [ownerDetails, setOwnerDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        websiteUrl: '',
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleDetailChange = (field) => (event) => {
        setOwnerDetails((prevDetails) => ({
            ...prevDetails,
            [field]: event.target.value,
        }));
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setIsLoading(true);
        localStorage.setItem('isRegistered', 1);

        if (!termsAccepted) {
            setErrorMessage('You must accept the terms and conditions.');
            setIsSubmitting(false);
            setIsLoading(false);
            return;
        }

        const updatedOwnerDetails = {
            ...ownerDetails,
        };

        localStorage.setItem('ownerDetails', JSON.stringify(updatedOwnerDetails));

        try {
            const response = await fetch(`${API_URL}/register_user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: ownerDetails.firstName,
                    lastName: ownerDetails.lastName,
                    email: ownerDetails.email,
                    websiteUrl: localStorage.getItem('websiteUrl'),
                }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.detail);
            }

            const welcomeResponse = await fetch(`${API_URL}/send-welcome-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: ownerDetails.email,
                }),
            });

            if (welcomeResponse.ok) {
                navigate('/success');
            } else {
                const data = await welcomeResponse.json();
                setErrorMessage(data.detail);
            }
        } catch (error) {
            setErrorMessage('Failed to register user. Please try again later.');
        } finally {
            setIsSubmitting(false);
            setIsLoading(false);
        }
    };

    return (
        <div className="signup-container">
            {isLoading && (
                <div className="modal">
                    <div className="loading-modal-content">
                        <div className="spinner"></div>
                        <p>Signing you up...</p>
                    </div>
                </div>
            )}

            <div className="content">
                <h1>Register for <strong>Demystify AI Studio</strong></h1>
                <p>Like what you see? Join our waitlist for priority access.</p>
                <form className="signup-form" onSubmit={handleSignUp}>
                    <div className="form-group">
                        <label>First Name *</label>
                        <input
                            type="text"
                            value={ownerDetails.firstName}
                            onChange={handleDetailChange('firstName')}
                            placeholder="Enter your first name"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Last Name *</label>
                        <input
                            type="text"
                            value={ownerDetails.lastName}
                            onChange={handleDetailChange('lastName')}
                            placeholder="Enter your last name"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Email *</label>
                        <input
                            type="email"
                            value={ownerDetails.email}
                            onChange={handleDetailChange('email')}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                            />
                            I agree to the <a className="underline" href="https://www.demystifyai.org/terms-and-condition" target="_blank" rel="noopener noreferrer">terms of service</a> *
                        </label>
                    </div>

                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <button type="submit" className="submit-button" disabled={isSubmitting}>
                        Register
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Register;