import React, { useState } from 'react';
import { FaBars, FaUser, FaQuestionCircle, FaLink, FaPhoneAlt, FaYoutube } from 'react-icons/fa'; // Importing Font Awesome icons

export default function Header({ onNewChat, BotName }) {
    // State to control the visibility of the confirmation modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to handle the button click that triggers the modal
    const handleNewChatClick = () => {
        setIsModalOpen(true);
    };

    // Function to continue with the new chat
    const handleContinue = () => {
        setIsModalOpen(false);
        onNewChat(); // Proceed with the new chat initialization
    };

    // Function to cancel the new chat
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className="flex flex-row p-4 bg-slate-800 rounded-xl my-4">
                <img src="https://cdn.prod.website-files.com/66bbd75ce637215d048c9c3d/66c3f8f0a94447109749d5d3_android-chrome-512x512.png" alt="Logo" className="mr-4" style={{ width: '50px', height: '50px' }} />
                <a href="https://www.demystify-ai.org" target="_blank" rel="noopener noreferrer" className="text-2xl text-slate-200 font-semibold grow">
                    {BotName}
                </a>
                
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Let us know if you have any questions by emailing us at contact@demystify-ai.org or following us at the following links. Thanks!</h2>
                        <br />
                        <div>
                            {/* <button type="submit" onClick={handleContinue}>Continue</button> */}
                            <button type="cancel" onClick={handleCancel}>Return to Chat</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


