import Markdown from 'react-markdown';
// import { SiOpenai } from "react-icons/si";
import remarkGfm from "remark-gfm";

export default function ChatMessage({ message, role, firstName, lastName, websiteUrl }) {

    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : 'U';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase(): 'I';
    const userDisplayIntials = firstInitial + lastInitial;
    const userDisplayName = firstName && lastName ? `${firstName} ${lastName}` : "User";

    const roleIcon = role === "user"
        ? <div className="rounded-full h-8 w-8 bg-slate-600 flex items-center justify-center font-semibold text-slate-300 shrink-0">{userDisplayIntials}</div>
        : <div className="rounded-full h-9 w-9 flex items-center justify-center font-semibold text-slate-50 shrink-0"><img src="https://cdn.prod.website-files.com/66bbd75ce637215d048c9c3d/66c3f8f0a94447109749d5d3_android-chrome-512x512.png" alt="Logo" style={{ width: '40px', height: '40px' }} /></div>;

    const roleName = role === "user"
        ? userDisplayName
        : websiteUrl;

    return (
        <div className="flex flex-row mx-2 my-3">
            {roleIcon}
            <div className="p-1 ml-2">
                <div className="flex-col">
                    <p className="font-semibold text-slate-400">{roleName}</p>
                    <Markdown
                        className="text-slate-50 markdown"
                        remarkPlugins={[remarkGfm]}
                    >
                        {message}
                    </Markdown>
                </div>
            </div>
        </div>
    );
}