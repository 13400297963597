import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';  // Add custom styles here

function LoadingScreen() {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds + 1);
        }, 1000);

        return () => clearInterval(interval); // Clean up on component unmount
    }, []);

    // Format the time to MM:SS
    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const secs = totalSeconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className="loading-screen">
            <h2>Creating AI Assistant </h2>
            <p>We are going to scan your website and create a tailored AI Assistant in up to 2 minutes.</p>
            <br/>
            <span>{formatTime(seconds)}</span>
            <div className="loading-circle">
                <span></span>
            </div>
        </div>
    );
}

export default LoadingScreen;