import axios from 'axios';
export const API_URL = process.env.REACT_APP_API_URL;

export const createNewThreadwithURL = async (url) => {
    try {
        const response = await fetch(`${API_URL}/api/new/site/${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (err) {
        console.error(`Error in createNewThreadwithURL: ${err.message}`);
    }
};

export const createNewThread = async (assistantId) => {
    try {
        const response = await fetch(`${API_URL}/api/new/${assistantId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (err) {
        console.error(`Error in createNewThread: ${err.message}`);
    }
};


export const createNewUser = async (userData) => {
    try {
        let response = await fetch(`${API_URL}/api/new_user`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData) // Sending the user data as JSON
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json(); // Parses the JSON response
    } catch (err) {
        console.error("Error creating new user:", err.message);
        throw err; // Rethrow to let caller handle the error too
    }
}

export const fetchThread = async (threadId) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const fetchRun = async (threadId, runId) => {
    try {
        console.log(threadId);
        let response = await fetch(`${API_URL}/api/threads/${threadId}/runs/${runId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const postMessage = async (threadId, message) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                content: message,
            })
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const postToolResponse = async (threadId, runId, toolResponses) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}/runs/${runId}/tool`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(toolResponses)
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const postUrlForTraining = async (url) => {
    try {
        const response = await fetch(`${API_URL}/train_only`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ url })
        });
        return response.json();
    } catch (err) {
        console.error("Error during Firecrawl process:", err.message);
    }
}

export const fetchStatistics = () => {
    return axios.get(`${API_URL}/statistics`);
}


// Fetch assistant data by BotID
export const fetchAssistantData = async (botid) => {
    try {
        const response = await axios.get(`${API_URL}/api/get/assistants/${botid}`);
        return response.data; // Return the data directly
    } catch (error) {
        console.error(`Error in fetchAssistantData: ${error.message}`);
        return null; // Return null if an error occurs
    }
};

// Update assistant data
export const updateAssistantData = async (assistantData) => {
    try {
        const response = await axios.put(`${API_URL}/api/update/assistants/${assistantData.botid}`, assistantData);
        return response.status === 200; // Return true if the update was successful
    } catch (error) {
        console.error(`Error in updateAssistantData: ${error.message}`);
        return false; // Return false if an error occurs
    }
};

// Fetch User Data by User ID
export const fetchUserData = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/api/users/${userId}`);
        return response.data; // Return the data directly
    } catch (error) {
        console.error(`Error in fetchUserData: ${error.message}`);
        return null; // Return null if an error occurs
    }
};

// Fetch Bot Data by Bot ID
export const fetchBotData = async (botId) => {
    try {
        const response = await axios.get(`${API_URL}/api/bots/${botId}`);
        return response.data; // Return the data directly
    } catch (error) {
        console.error(`Error in fetchBotData: ${error.message}`);
        return null; // Return null if an error occurs
    }
};