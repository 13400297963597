import React from 'react';

function ChatOption({ options = [], onOptionClick }) {
    if (!options.length) return null; // Don't render if there are no options

    return (
        <div className="flex flex-col p-3">
            {options.map((option, index) => (
                <button
                    key={index}
                    className="bg-slate-600 text-slate-200 py-2 px-4 rounded mb-2"
                    onClick={() => onOptionClick(option)} // Send the option as a message
                >
                    {option}
                </button>
            ))}
        </div>
    );
}

export default ChatOption;