import React, { useState } from 'react';
import { MdSend } from 'react-icons/md';

export default function ChatInput({ disabled, onSend, isSending }) {
    const [message, setMessage] = useState("");

    return (
        <form
            className="border-2 border-slate-400 rounded-lg p-2 flex flex-row m-2 text-slate-50"
            onSubmit={(e) => {
                e.preventDefault();
                if (!message.trim()) return; // Prevent sending empty messages
                onSend(message);
                setMessage(""); // Clear the message input after send
            }}
            autoComplete="off"
        >
            <input
                name="message"
                placeholder={isSending ? "Please wait, your answer is coming soon..." : "Ask me any questions you have..."}  // Conditional placeholder text
                className="w-full bg-slate-800 focus:outline-none"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={disabled} // Use disabled prop to disable input
            />
            <button
                disabled={disabled} // Use disabled prop to disable button
                className="bg-slate-500 text-white font-bold py-2 px-4 rounded hover:bg-pink-400 disabled:bg-slate-600 disabled:text-slate-400"
            >
                <MdSend/>
            </button>
        </form>
    );
}
