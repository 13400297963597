import React, { useState } from 'react';
import './Customization.css';
import { fetchAssistantData, updateAssistantData } from '../../services/api.js'; // Import necessary functions

const Customization = () => {
  const [assistantData, setAssistantData] = useState({
    botid: '',
    assistantId: '',
    assistantName: '',
    convoStarterOne: '',
    convoStarterTwo: '',
    beginMessage: '',
    logoUrl: '',
    color: '',
    isAutoOpen: false,
    proactiveBubbleText: '',
  });
  const [formError, setFormError] = useState('');
  const [isAssistantLoaded, setIsAssistantLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  // Submit Assistant ID to fetch data
  const handleAssistantIdSubmit = async () => {
    setFormError('');
    setLoading(true);
    try {
      const data = await fetchAssistantData(assistantData.botid);
      if (data) {
        setAssistantData({
          ...assistantData,
          assistantId: data.assistantId,
          assistantName: data.assistantName,
          convoStarterOne: data.convoStarterOne,
          convoStarterTwo: data.convoStarterTwo,
          beginMessage: data.beginMessage,
          logoUrl: data.logoUrl,
          color: data.color,
          isAutoOpen: data.isAutoOpen,
          proactiveBubbleText: data.proactiveBubbleText,
        });
        setIsAssistantLoaded(true);
      } else {
        setFormError('Assistant ID does not exist.');
        setIsAssistantLoaded(false);
      }
    } catch (error) {
      setFormError('Error fetching assistant data.');
    } finally {
      setLoading(false);
    }
  };

  // Update Assistant Data
  const handleUpdateAssistant = async () => {
    setFormError('');
    setLoading(true);
    try {
      const success = await updateAssistantData(assistantData);
      if (success) {
        alert('Assistant data updated successfully');
      } else {
        setFormError('Failed to update assistant data.');
      }
    } catch (error) {
      setFormError('Error updating assistant data.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="customization-container">
      {/* Visual Appearance Content */}
      <div className="visual-appearance-content">
        <h3>Customize your AI Agent's Visual Appearance</h3>
        <br />
        <div className="form-group">
          <label htmlFor="assistantId">Enter your Bot ID: </label>
          <input
            type="text"
            id="assistantId"
            value={assistantData.botid}
            onChange={(e) => setAssistantData({ ...assistantData, botid: e.target.value })}
          />
          <button className="submit-btn" onClick={handleAssistantIdSubmit}>
            Submit
          </button>
          {formError && <p className="error-message">{formError}</p>}
        </div>

        {isAssistantLoaded && (
          <div className="assistant-form">
            <div className="form-group">
              <label htmlFor="assistantName">Assistant Name: </label>
              <input
                type="text"
                id="assistantName"
                value={assistantData.assistantName}
                onChange={(e) => setAssistantData({ ...assistantData, assistantName: e.target.value })}
              />
            </div>
            <div className="form-group">
                <label htmlFor="convoStarterOne">Conversation Starter One: </label>
                <input
                  type="text"
                  id="convoStarterOne"
                  value={assistantData.convoStarterOne}
                  onChange={(e) => setAssistantData({ ...assistantData, convoStarterOne: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="convoStarterTwo">Conversation Starter Two: </label>
                <input
                  type="text"
                  id="convoStarterTwo"
                  value={assistantData.convoStarterTwo}
                  onChange={(e) => setAssistantData({ ...assistantData, convoStarterTwo: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="beginMessage">Beginning Message: </label>
                <input
                  type="text"
                  id="beginMessage"
                  value={assistantData.beginMessage}
                  onChange={(e) => setAssistantData({ ...assistantData, beginMessage: e.target.value })}
                />
              </div>
            <div className="form-group">
              <label htmlFor="logoUrl">Logo URL: </label>
              <input
                type="text"
                id="logoUrl"
                value={assistantData.logoUrl}
                onChange={(e) => setAssistantData({ ...assistantData, logoUrl: e.target.value })}
              />
            </div>

            {/* Color Dropdown and Custom Hex Input */}
            <div className="form-group">
              <label htmlFor="color">Select Color: </label>
              <select
                id="color"
                value={assistantData.color}
                onChange={(e) => setAssistantData({ ...assistantData, color: e.target.value })}
              >
                <option value="">Select a Color</option>
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="yellow">Yellow</option>
                <option value="black">Black</option>
                <option value="white">White</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="customColor">Or Enter Custom Hex Color: </label>
              <input
                type="color"
                id="customColor"
                value={assistantData.color}
                onChange={(e) => setAssistantData({ ...assistantData, color: e.target.value })}
              />
              <input
                type="text"
                value={assistantData.color}
                onChange={(e) => setAssistantData({ ...assistantData, color: e.target.value })}
              />
            </div>

            {/* isAutoOpen Checkbox */}
            <div className="form-group">
              <label htmlFor="isAutoOpen">Auto Open Chat for Desktop: </label>
              <input
                type="checkbox"
                id="isAutoOpen"
                checked={assistantData.isAutoOpen}
                onChange={(e) => setAssistantData({ ...assistantData, isAutoOpen: e.target.checked })}
              />
            </div>

            {/* ProactiveBubbleText Field */}
            <div className="form-group">
              <label htmlFor="proactiveBubbleText">Proactive Bubble Text: </label>
              <input
                type="text"
                id="proactiveBubbleText"
                value={assistantData.proactiveBubbleText}
                onChange={(e) => setAssistantData({ ...assistantData, proactiveBubbleText: e.target.value })}
              />
            </div>

            <button className="update-btn" onClick={handleUpdateAssistant}>
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Customization;