import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Main.css';

const Main = () => {
    const [websiteUrl, setWebsiteUrl] = useState('');  // State for website URL input
    const [error, setError] = useState("");
    const navigate = useNavigate(); // Use navigate for navigation to sign-up or login

    // Function to validate if the entered URL is a valid domain
    const isValidDomain = (url) => {
        const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;
        return domainRegex.test(url);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setError("");

        if (!isValidDomain(websiteUrl)) {
            setError("Please enter a valid domain (e.g., example.com or www.example.com).");
            return;
        }

        navigate(`/onboarding?url=${encodeURIComponent(websiteUrl)}`);
    };

    return (
        <div className="content">
            <h1>Don't start from Scratch <span>Start with Your Website!</span></h1>
            <p>In just 2 minutes, you can start chatting with your own website using the magic of AI. Enter the link to your business below and see the power of <strong>Demystify AI Studio</strong>.</p>
            
            <form className="website-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="example.com"
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)} // Capture the input value
                    required
                />
                <button type="submit">Try your AI Agent for Free</button>
            </form>

            {error && <p style={{ color: "red" }}>{error}</p>}

            <br />
            <p>Already registered with Us? Click the Login Button below:</p>
            <div className="auth-buttons">
                <button className="toggle-login-button" onClick={() => navigate('/login')}>
                    Log In
                </button>
            </div>
        </div>
    );
};


export default Main;