import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import Header from "../../components/Chat/Header";
import ChatInput from "../../components/Chat/ChatInput";
import ChatMessage from "../../components/Chat/ChatMessage";
import ChatStatusIndicator from "../../components/Chat/ChatStatusIndicator";
import ChatOption from '../../components/Chat/ChatOption';
import Loading from "../../components/Chat/Loading";
import { useThread } from '../../hooks/useThread';
import { useRunPolling } from '../../hooks/useRunPolling';
import { useRunRequiredActionsProcessing } from '../../hooks/useRunRequiredActionsProcessing';
import { useRunStatus } from '../../hooks/useRunStatus';
import { postMessage } from "../../services/api";
export const API_URL = process.env.REACT_APP_API_URL;

const Onboarding = () => {
    const [run, setRun] = useState(undefined);
    const [isSending, setIsSending] = useState(false);
    const [conversationStarters, setConversationStarters] = useState([]); 
    const [isModalVisible, setIsModalVisible] = useState(false); 
    const [isLoading, setIsLoading] = useState(false); // New loading state for training
    const { status, processing } = useRunStatus(run);
    const [searchParams] = useSearchParams();
    const websiteUrl = searchParams.get('url'); // Get URL parameter

    var ownerDetails = getOwnerDetailsFromLocalStorage();
    ownerDetails = { ...ownerDetails, websiteUrl }; // Update the owner details with the website URL
    saveOwnerDetailsToLocalStorage(ownerDetails); // Save updated owner details back to local storage
    const isRegistered = getIsRegisteredFromLocalStorage();
    const navigate = useNavigate();

    const { threadId, messages, setActionMessages, clearThread } = useThread(run, setRun);
    const isChatDisabled = messages.length >= 7 && !isRegistered;

    useRunPolling(threadId, run, setRun);
    useRunRequiredActionsProcessing(run, setRun, setActionMessages);

    useEffect(() => {
        // Trigger train_only API call if `url` is present
        const initiateTraining = async () => {
            if (websiteUrl) {
                setIsLoading(true); // Show loading screen
                try {
                    await fetch(`${API_URL}/train_only`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ domain: websiteUrl }),
                    });
                    console.log("Training with firecrawl complete");
                } catch (error) {
                    console.error("Error during training: ", error);
                } finally {
                    setIsLoading(false); // Hide loading screen after training
                }
            }
        };

        initiateTraining();
    }, [websiteUrl]);

    useEffect(() => {
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1].content;
            const starters = extractConversationStarters(lastMessage);
            setConversationStarters(starters);
        }
    }, [messages]);

    const extractConversationStarters = (message) => {
        const starters = [];
        const lines = message.split('\n');
        let cleanedMessage = '';

        lines.forEach((line) => {
            if (line.startsWith('Starter 1:')) {
                starters.push(line.replace('Starter 1:', '').trim());
            } else if (line.startsWith('Starter 2:')) {
                starters.push(line.replace('Starter 2:', '').trim());
            } else {
                cleanedMessage += line + '\n';
            }
        });

        messages[messages.length - 1].content = cleanedMessage.trim();
        return starters;
    };

    const handleSend = (message) => {
        setIsSending(true);
        postMessage(threadId, message)
            .then(response => setRun(response))
            .finally(() => setIsSending(false));
    };

    useEffect(() => {
        if (isChatDisabled) {
            setIsModalVisible(true);
        }
    }, [isChatDisabled]);

    const messageList = messages
        .toReversed()
        .filter((message) => message.hidden !== true)
        .map((message) => (
            <ChatMessage
                message={message.content}
                role={message.role}
                key={message.id}
                firstName={ownerDetails.firstName || "User"}
                lastName={ownerDetails.lastName || ""}
                websiteUrl={ownerDetails.websiteUrl || "example.com"}
            />
        ));

    return (
        <div className="md:container md:mx-auto lg:px-32 h-screen bg-slate-800 flex flex-col">
            {isLoading ? (
                <LoadingScreen /> // Show LoadingScreen during training
            ) : (
                <>
                    <Header onNewChat={clearThread} BotName={websiteUrl} />
                    <div className="flex flex-col-reverse grow overflow-scroll">
                        {status !== undefined && <ChatStatusIndicator status={status} />}
                        {processing && <Loading />}
                        {messageList}
                    </div>
                    <ChatOption
                        options={conversationStarters}
                        onOptionClick={(option) => handleSend(option)}
                    />
                    <div className="my-4">
                        <ChatInput
                            onSend={(message) => handleSend(message)}
                            isSending={isSending}
                            disabled={processing || isSending || isChatDisabled}
                        />
                    </div>
                </>
            )}
            {!isRegistered && isModalVisible && (
                <Modal
                    title="Register Now"
                    message="You have reached the message limit. Please register to continue chatting."
                    onConfirm={() => navigate('/register')}
                    onClose={() => setIsModalVisible(false)}
                />
            )}
            {!isRegistered ? (
                <button className="toggle-signup-button" onClick={() => navigate('/register')}>
                    Register Now
                </button>
            ) : (
                <button className="toggle-signup-button" onClick={() => navigate('/success')}>
                    Back to Success Page
                </button>
            )}
        </div>
    );
};

function getIsRegisteredFromLocalStorage() {
    return localStorage.getItem('isRegistered') === '1';
}

const Modal = ({ title, message, onConfirm }) => (
    <div className="modal">
        <div className="modal-content">
            <h2>{title}</h2>
            <p>{message}</p>
            <button className="confirm-button" onClick={onConfirm}>
                Register
            </button>
        </div>
    </div>
);


// Fetch owner details from local storage, or return a default object if not found
function getOwnerDetailsFromLocalStorage() {
    const details = localStorage.getItem('ownerDetails');
    const defaultOwnerDetails = {
        firstName: "User",
        lastName: "Name",
        email: "user@test.org",
        websiteUrl: "example.com"
    };

    if (details) {
        try {
            return JSON.parse(details);
        } catch (error) {
            console.error("Failed to parse owner details from local storage:", error);
            return defaultOwnerDetails;
        }
    }
    return defaultOwnerDetails;
}

// Save updated owner details to local storage
function saveOwnerDetailsToLocalStorage(ownerDetails) {
    try {
        localStorage.setItem('ownerDetails', JSON.stringify(ownerDetails));
        localStorage.setItem('websiteUrl', ownerDetails.websiteUrl);
        console.log("Owner details saved to local storage:", ownerDetails);
    } catch (error) {
        console.error("Failed to save owner details to local storage:", error);
    }
}

export default Onboarding;