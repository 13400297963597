import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from "../../components/Chat/Header";
import ChatInput from "../../components/Chat/ChatInput";
import ChatMessage from "../../components/Chat/ChatMessage";
import ChatStatusIndicator from "../../components/Chat/ChatStatusIndicator";
import ChatOption from '../../components/Chat/ChatOption';
import { useThread } from '../../hooks/useThread';
import { useRunPolling } from '../../hooks/useRunPolling';
import { useRunRequiredActionsProcessing } from '../../hooks/useRunRequiredActionsProcessing';
import { useRunStatus } from '../../hooks/useRunStatus';
import { postMessage } from "../../services/api";
export const API_URL = process.env.REACT_APP_API_URL;

const Share = () => {
    const [run, setRun] = useState(undefined);
    const [isSending, setIsSending] = useState(false);
    const [conversationStarters, setConversationStarters] = useState([]); 
    const [searchParams] = useSearchParams();
    const botId = searchParams.get('botid'); // Get bot ID parameter

    const { status, processing } = useRunStatus(run);
    const { threadId, messages, setActionMessages, clearThread } = useThread(run, setRun);

    useRunPolling(threadId, run, setRun);
    useRunRequiredActionsProcessing(run, setRun, setActionMessages);

    useEffect(() => {
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1].content;
            const starters = extractConversationStarters(lastMessage);
            setConversationStarters(starters);
        }
    }, [messages]);

    const extractConversationStarters = (message) => {
        const starters = [];
        const lines = message.split('\n');
        let cleanedMessage = '';

        lines.forEach((line) => {
            if (line.startsWith('Starter 1:')) {
                starters.push(line.replace('Starter 1:', '').trim());
            } else if (line.startsWith('Starter 2:')) {
                starters.push(line.replace('Starter 2:', '').trim());
            } else {
                cleanedMessage += line + '\n';
            }
        });

        messages[messages.length - 1].content = cleanedMessage.trim();
        return starters;
    };

    const handleSend = (message) => {
        setIsSending(true);
        postMessage(threadId, message)
            .then(response => setRun(response))
            .finally(() => setIsSending(false));
    };

    const messageList = messages
        .toReversed()
        .filter((message) => message.hidden !== true)
        .map((message) => (
            <ChatMessage
                message={message.content}
                role={message.role}
                key={message.id}
            />
        ));

    return (
        <div className="md:container md:mx-auto lg:px-32 h-screen bg-slate-800 flex flex-col">
            <Header onNewChat={clearThread} BotName={botId} />
            <div className="flex flex-col-reverse grow overflow-scroll">
                {status !== undefined && <ChatStatusIndicator status={status} />}
                {processing && <ChatOption options={conversationStarters} onOptionClick={(option) => handleSend(option)} />}
                {messageList}
            </div>
            <div className="my-4">
                <ChatInput
                    onSend={(message) => handleSend(message)}
                    isSending={isSending}
                    disabled={processing || isSending}
                />
            </div>
        </div>
    );
};

export default Share;