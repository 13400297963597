import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../SignUp/SignUp.css'; // Use the same CSS as the Register page
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch(`${API_URL}/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: username,
                    password: password,
                }),
            });

            const result = await response.json();

            if (response.ok && result.success) {
                navigate('/customization');
            } else {
                setErrorMessage('Login failed: ' + result.message);
            }
        } catch (error) {
            console.error('Error during authentication:', error);
            setErrorMessage('An error occurred while trying to authenticate');
        } finally {
            setIsSubmitting(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="signup-container"> {/* Use 'signup-container' for consistency */}
            <div className="content"> {/* Reuse 'content' for alignment */}
                <h1>Login to <strong>Demystify AI Studio</strong></h1>
                <p>Welcome back! Please log in to your account.</p>

                <form className="signup-form" onSubmit={handleSubmit}> {/* Use 'signup-form' */}
                    <div className="form-group">
                        <label>Email: </label>
                        <input
                            type="text"
                            placeholder="Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Password: </label>
                        <div className="password-wrapper">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="show-password-button"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>

                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <button type="submit" className="submit-button" disabled={isSubmitting}>
                        {isSubmitting ? 'Logging in...' : 'Login'}
                    </button>

                    <p className="disclaimer">
                        New to Demystify AI Studio Build-a-Bot? <br />
                        <a href="/">Get your AI Assistant within 2 minutes!</a>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;